<template>
  <div class="Recharge">
    <div class="wallent_content">
      <div style="margin-top: 20px; font-size: 16px; font-weight: bold;"></div>
      <van-form @submit="onSubmit">
        <div style=""></div>
        <van-cell :border="false" style="padding-bottom: 0px; border-top-left-radius: 5px; border-top-right-radius: 5px;">
          <template #title>
            <span>消费金额</span>
          </template>
        </van-cell>
        
        <van-cell class="paymoney">
          <template #icon><span style="font-size: 20px; font-weight: bold;">&yen;</span></template>
          <template #title><span class="money">{{money}}</span><em class="guangbiao"></em></template>
        </van-cell>

        <van-cell :border="false" style="padding: 20px 16px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
          <template #title>
          </template>
        </van-cell>
        
        <!-- <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            确认支付
          </van-button>
        </div> -->
      </van-form>
      <div class="blank"></div>
      <div class="bottom">
        <div class="keyboard" style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" @click.stop='_handleKeyPress'>
          <ul>
            <li class="border-r border-b" data-value="1">1</li>
            <li class="border-r border-b" data-value="2">2</li>
            <li class="border-r border-b" data-value="3">3</li>
            <li class="border-r border-b" data-value="4">4</li>
            <li class="border-r border-b" data-value="5">5</li>
            <li class="border-r border-b" data-value="6">6</li>
            <li class="border-r border-b" data-value="7">7</li>
            <li class="border-r border-b" data-value="8">8</li>
            <li class="border-r border-b" data-value="9">9</li>
            <li class="border-r" data-value="0">0</li>
            <li class="border-r" data-value="dot">.</li>
          </ul>
          <dl>
            <dt data-value="delete"></dt>
            <dd class="" data-value="submit" style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">付<br>款</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Recharge",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      money: '',
      usetype: 0,
      noMoney: false,
      noText: "",
      userinfo: {},
      param: {}, // 支付参数
      zid: ''
    }
  },
  mounted() {
    window.payOk = this.payOk; // 支付成功 供外部调用

    this.zid = this.$route.query.zid;
    let openid = null;
    openid = localStorage.getItem("SHOP_OPENID");
    if (!openid && window.isWeixin()) {
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        // 取openid
        this.getwxlog(this.code);
        return false;
      } else {
        // 取code
        this.getwxinfo();
        return false;
      }
    }
  },
  methods: {
    getwxinfo() {
      let _that = null;
      _that = this;
      let openid = null;
      openid = localStorage.getItem("SHOP_OPENID");
      // 微信打开获取code
      if (!openid && window.isWeixin()) { 
        const wxAuthUrl2 = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx75ee19385a00c0b8&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      }
    },
    // 取openid
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "team/auth/wxOAuth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            // 获得用户的openid
            localStorage.setItem("SHOP_OPENID", response.data.openid);
            localStorage.setItem("SHOP_UNIONID", response.data.unionid);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    //处理按键
    _handleKeyPress(e) {
      
      const num = e.target.dataset.value;

      //不同按键处理逻辑
      // -1 代表无效按键，直接返回
      if (num == -1) return false;

      switch (String(num)) {
        //小数点
        case 'dot':
          this._handleDecimalPoint();
          break;
        //删除键
        case 'delete':
          this._handleDeleteKey();
          break;
        //确认键
        case 'submit':
          this._handleConfirmKey();
          break;
        default:
          this._handleNumberKey(num);
          break;
      }
    },
    //处理小数点函数
    _handleDecimalPoint() {
      //如果包含小数点，直接返回
      if (this.money.indexOf('.') > -1) return false;
      //如果小数点是第一位，补0
      if (!this.money.length)
        this.money = '0.';
      //如果不是，添加一个小数点
      else
        this.money = this.money + '.';
    },
    //处理删除键
    _handleDeleteKey() {
      //如果没有输入，直接返回
      if (!this.money.length) return false;
      //否则删除最后一个
      this.money = this.money.substring(0, this.money.length - 1);
    },
    //处理数字
    _handleNumberKey(num) {
      //如果有小数点且小数点位数不小于2
      if ( this.money.indexOf('.') > -1 && this.money.substring(this.money.indexOf('.') + 1).length < 2)
        this.money = this.money + num;
      //没有小数点
      if (!(this.money.indexOf('.') > -1)) {
        //如果第一位是0，只能输入小数点
        if (num == 0 && this.money.length == 0)
          this.money = '0.';
        else {
          if (this.money.length && Number(this.money.charAt(0)) === 0) return;
          this.money = this.money + num;
        }
      }
    },
    //处理确认键
    _handleConfirmKey() {
      //未输入
      if (!this.money.length){
        alert( '请输入要支付的金额!' )
        return false;
      }
      //将 8. 这种转换成 8.00
      if (this.money.indexOf('.') > -1 && this.money.indexOf('.') == (this.money.length - 1))
        this.money = Number(this.money.substring(0, this.money.length - 1)).toFixed(2);
      //保留两位
      this.money = Number(this.money).toFixed(2);
      this.onSubmit();
    },
    // 执行付款接口
    onSubmit() {
      let _that = null;
      _that = this;
      if(_that.money.length <= 0 || parseFloat(_that.money) <= 0) {
        _that.$toast("请输入要支付的金额");
        return false;
      }
      // 获取支付参数
      _that.$axios
        .post(
          _that.$store.state.domain + "team/pay/fan",
          _that.$qs.stringify({
            openid: localStorage.getItem("SHOP_OPENID"),
            unionid: localStorage.getItem("SHOP_UNIONID"),
            money: _that.money,
            zid: _that.zid
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            _that.param = {
              appId: response.data.data.appId,
              timeStamp: response.data.data.timeStamp,
              nonceStr: response.data.data.nonceStr,
              package: response.data.data.package,
              signType: response.data.data.signType,
              paySign: response.data.data.paySign
            }
            window.onwxpay(_that.param)
          }else {
            _that.$toast(response.data.msg);
          }
        })
    },
    // 返回支付结果
    payOk() {
      this.$toast("支付成功");
      this.$router.replace({ name: "PaySuccess" });
    },
  }
}
</script>
<style lang="less">
body,dl,dd,ul,p,pre,h1,h2,h3,h4,h5,h6,button,input,textarea,form {
	margin: 0
}
.paymoney em{
  width: 2px;
	height: 26px;
	display: inline-block;
	opacity: 0;
	background: #4081e1;
	margin-left: -3px;
	-webkit-animation: cursor 1s ease-in-out infinite;
	vertical-align: middle;
}
.Recharge {
  background-color: #efeff4;
  margin: 0;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #333;
    }
  }
  .wallent_content{
    overflow: hidden;
    .money {
      margin-left: 12px;
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 1px;
    }
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        padding-top: 10px;
        color: #333;
        font-size: 16px;
        span {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .van-form {
      margin: 10px 16px;
      border-radius: 5px;
      .van-cell {
        .van-cell__title {
          text-align: left;
          font-weight: 500;
        }
      }
      .van-cell::after {
        border-bottom: 2px solid #e1e1e4;
      }
    }

    .blank {
      height: 270px
    }

    .bottom {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      -webkit-user-select: none;
      user-select: none
    }

    .keyboard {
      background: #fff;
      width: 100%;
      height: 232px
    }

    .keyboard ul {
      width: 75%;
      height: 100%;
      float: left
    }

    .keyboard li {
      width: 33.3%;
      height: 25%;
      float: left;
      line-height: 58px;
      font-size: 24px;
      text-align: center;
      color: #666;
      -webkit-transition: .1s;
      font-family: PingFangSC-Regular,Helvetica
    }

    .keyboard li:nth-child(10) {
      width: 66.6%
    }

    .keyboard li:nth-child(3),.keyboard li:nth-child(6),.keyboard li:nth-child(9),.keyboard li:nth-child(11) {
      width: 33.4%
    }

    .keyboard li:active {
      background-color: #f2f5f7
    }

    .keyboard dl {
      width: 25%;
      height: 100%;
      float: left
    }

    .keyboard dt {
      width: 100%;
      height: 25%;
      background: url(/img/icons/del.png) no-repeat center center;
      background-size: 34px 24px
    }

    .keyboard dt:active {
      background-color: #f5f5f5
    }

    .keyboard dd {
      height: 124px;
      padding-top: 50px;
      font-size: 24px;
      text-align: center;
      color: #fff;
      background-color: #4081e1
    }

    .keyboard dd:active {
      background-color: #3872C9
    }

    .keyboard dd.disable {
      background-color: #a0c0f0!important
    }

    @media screen and (min-height:560px) {
      .blank {
        height: 314px
      }

      .keyboard {
        height: 276px
      }

      .keyboard li {
        line-height: 69px
      }

      .keyboard dd {
        height: 132px;
        padding-top: 75px
      }
    }

    .border-r,.border-b,.border-t {
      position: relative
    }

    .border-t:after {
      width: 100%;
      height: 1px;
      display: block;
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      background: #efeff4;
      -webkit-transform: scaleY(0.5);
      -webkit-transform-origin: left top
    }

    .border-r:before {
      width: 1px;
      height: 100%;
      display: block;
      content: " ";
      position: absolute;
      right: 0;
      top: 0;
      background: #efeff4;
      -webkit-transform: scaleX(0.5);
      -webkit-transform-origin: right top
    }

    .border-b:after {
      width: 100%;
      height: 1px;
      display: block;
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      background: #efeff4;
      -webkit-transform: scaleY(0.5);
      -webkit-transform-origin: left bottom
    }

  }
}
</style>